<template>
  <v-row no-gutters class="flex-column">
    <v-card dark tile outlined :loading="form.loading">
      <v-card-title> Event Baru </v-card-title>
      <v-card-text>
        <h4>
          Informasi Event
          <v-btn icon @click="$refs.event.reset()" class="ml-2">
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </h4>

        <v-form ref="event">
          <v-text-field
            label="Judul"
            v-model="form.data.title"
            :rules="form.rules.title"
          ></v-text-field>
          <v-row>
            <v-col>
              <v-text-field
                readonly
                label="Mulai"
                v-model="form.data.start_date"
                :rules="form.rules.start_date"
              >
                <template v-slot:append-outer>
                  <v-menu
                    ref="startDate"
                    v-model="datepick.start"
                    :close-on-content-click="false"
                    :return-value.sync="form.data.start_date"
                    offset-x
                    min-width="auto"
                  >
                    <template v-slot:activator="{ attrs, on }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-calendar</v-icon>
                      </v-btn>
                    </template>
                    <v-date-picker no-title v-model="form.data.start_date">
                      <v-spacer></v-spacer>
                      <v-btn icon @click="saveDate('startDate')">
                        <v-icon>mdi-check</v-icon>
                      </v-btn>
                      <v-btn icon @click="datepick.start = false">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </template>
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                readonly
                label="Selesai"
                v-model="form.data.end_date"
                :rules="form.rules.end_date"
              >
                <template v-slot:append-outer>
                  <v-menu
                    ref="endDate"
                    v-model="datepick.end"
                    :close-on-content-click="false"
                    :return-value.sync="form.data.end_date"
                    offset-x
                    min-width="auto"
                  >
                    <template v-slot:activator="{ attrs, on }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-calendar</v-icon>
                      </v-btn>
                    </template>
                    <v-date-picker no-title v-model="form.data.end_date">
                      <v-spacer></v-spacer>
                      <v-btn icon @click="saveDate('endDate')">
                        <v-icon>mdi-check</v-icon>
                      </v-btn>
                      <v-btn icon @click="datepick.end = false">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                type="number"
                label="Batas Peserta"
                v-model.number="form.data.participant_limit"
                :rules="form.rules.participant_limit"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-autocomplete
                label="Gratis Ongkir"
                v-model="form.data.shipment_cost"
                :rules="form.rules.shipment_cost"
                :items="allFreeShipment"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                label="Deadline"
                v-model="form.data.deadline"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-autocomplete
                label="Timezone"
                v-model="form.data.timezone"
                :items="timezone"
                item-text="text"
                item-value="value"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-file-input
            label="Banner Event"
            show-size
            accept="image/*"
            v-model="form.data.image"
            :rules="form.rules.image"
          ></v-file-input>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          depressed
          large
          @click="saveData('event')"
          :loading="form.loading"
          :disabled="form.loading"
        >
          <span>simpan</span>
          <v-icon right>mdi-content-save</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-snackbar v-model="snackbar.vm" :color="snackbar.color" multi-line>
      <v-icon left>
        {{ snackbar.icon }}
      </v-icon>
      {{ snackbar.text }}
      <v-spacer></v-spacer>
      <template v-slot:action>
        <v-btn large text @click="snackbar.vm = false">
          <span>close</span>
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      timezone: [
        { text: "UTC+07:00", value: "Asia/Jakarta" },
        { text: "UTC+08:00", value: "Asia/Makassar" },
        { text: "UTC+09:00", value: "Asia/Jayapura" },
      ],
      datepick: {
        start: false,
        end: false,
      },
      form: {
        loading: false,
        data: {
          title: null,
          start_date: null,
          end_date: null,
          deadline: null,
          timezone: null,
          participant_limit: null,
          shipment_cost: null,
          image: null,
        },
        rules: {
          title: [(v) => !!v || "Judul harus diisi"],
          start_date: [(v) => !!v || "Tanggal mulai harus diisi"],
          end_date: [(v) => !!v || "Tanggal selesai harus diisi"],
          participant_limit: [(v) => !!v || "Batas peserta harus diisi"],
          shipment_cost: [(v) => !!v || "Pilih gratis ongkir"],
          image: [
            (v) => !!v || "Pilih banner event",
            (value) =>
              !value ||
              value.size < 2000000 ||
              "Ukuran file banner harus kurang dari 2 MB!",
          ],
          event: [(v) => !!v || "Pilih event"],
          description: [(v) => !!v || "Deskripsi harus diisi"],
          max_winners: [(v) => !!v || "Batas pemenang harus diisi"],
        },
      },
      snackbar: {
        vm: false,
        color: null,
        icon: null,
        text: null,
      },
    };
  },
  methods: {
    ...mapActions(["createEvent", "addPrize", "addChallenge"]),
    saveDate(ref) {
      switch (ref) {
        case "startDate":
          this.$refs.startDate.save(this.form.data.start_date);
          break;
        case "endDate":
          this.$refs.endDate.save(this.form.data.end_date);
          break;
      }
    },

    saveData(form) {
      this.form.loading = true;

      switch (form) {
        case "event":
          this.saveEvent();
          break;
        case "prize":
          this.savePrize();
          break;
        case "challenge":
          this.saveChallenge();
          break;
      }
    },
    saveEvent() {
      // initiate data, form data, and validate data
      let data = this.form.data;
      let formData = new FormData();
      let isValid = this.$refs.event.validate();

      // if form is valid, then add data to form data
      if (isValid) {
        formData.append("title", data.title);
        formData.append("start_date", data.start_date);
        formData.append("end_date", data.end_date);
        formData.append("deadline", data.deadline);
        formData.append("timezone", data.timezone);
        formData.append("participant_limit", data.participant_limit);
        formData.append("shipment_cost", data.shipment_cost);
        formData.append("image", data.image);

        // console.log(formData);
        //save the data
        if (this.createEvent(formData)) {
          this.showNotification("success");
          this.form.loading = false;
        } else {
          this.form.loading = false;
          this.showNotification("failed");
        }
      } else {
        this.form.loading = false;
        this.showNotification("failed");
      }
    },
    savePrize() {
      let data = this.form.data.prizes;
      let isValid = this.$refs.prize.validate();

      //check form validation
      if (isValid) {
        //save prize
        for (let item of data) {
          this.addPrize(item);
        }
        this.form.loading = false;
        this.showNotification("success");
      } else {
        this.form.loading = false;
        this.showNotification("failed");
      }
    },
    showNotification(status) {
      this.snackbar.vm = true;
      switch (status) {
        case "success":
          this.snackbar.color = "success";
          this.snackbar.icon = "mdi-check-circle";
          this.snackbar.text = "Data berhasil disimpan";
          break;
        case "failed":
          this.snackbar.color = "error";
          this.snackbar.icon = "mdi-close-circle";
          this.snackbar.text = "Data gagal disimpan";
          break;
      }
    },
  },
  computed: {
    ...mapGetters(["allFreeShipment"]),
  },
};
</script>